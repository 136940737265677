<template>
<div>
    <a-card title="仓库管理">
        <template #extra>
            <a-space>
                <a-input-search
                    style="width: 350px"
                    v-model:value="epState.search.key"
                    placeholder="仓库名称"
                    enter-button
                    @search="getEnterPot(1,epState.limit)"
                />
                <a-button type="primary" @click="showEditEnterPot(null)" v-has="{action:'wxapp_storage_warehouse_add',plat:0}">
                    <i class="ri-add-line ri-top"></i>添加仓库
                </a-button>
            </a-space>
        </template>

        <a-table :pagination="false" :data-source="epState.list" row-key="id" :columns="[
            {title:'仓库编号',dataIndex:'id'},
            {title:'仓库名称',dataIndex:'name'},
            {title:'仓库地址',dataIndex:'address'},
            {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" :row-selection="{ selectedRowKeys: epState.ids, onChange:(e)=>{epState.ids = e}  }">
            <template #status="{record}">
                <a-tooltip :title="record.status ?'切换为禁用状态':'切换为启用状态'">
                    <a-tag class="pt" :color="record.status ?'#00CC66':'#FF0066'"
                           @click="changeEnterPot([record.id],record.status === 1 ? 0 : 1)">
                        {{record.status === 1 ?'启用':'禁用'}}
                    </a-tag>
                </a-tooltip>
            </template>
            <template #action="{record}">
                <kd-button title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_storage_warehouse_add',plat:0}"
                           @click="showEditEnterPot(record)">
                </kd-button>
                <kd-button title="删除" icon="ri-delete-bin-2-line" type="danger"
                           v-has="{action:'wxapp_storage_warehouse_delete',plat:0}"
                           @click="deleteEnterPot([record.id])">
                </kd-button>
            </template>
        </a-table>
        <kd-pager :page-data="epState" :event="getEnterPot">
            <a-space>
                <a-button @click="changeEnterPot(epState.ids,0)"  v-has="{action:'wxapp_storage_warehouse_status',plat:0}">禁用</a-button>
                <a-button @click="changeEnterPot(epState.ids,1)"  v-has="{action:'wxapp_storage_warehouse_status',plat:0}">启用</a-button>
                <a-button @click="deleteEnterPot(epState.ids)"  v-has="{action:'wxapp_storage_warehouse_delete',plat:0}">删除</a-button>
            </a-space>
        </kd-pager>
    </a-card>

    <a-modal title="添加仓库" width="600px" v-model:visible="eepState.show"
             @ok="saveEnterPot"
             @cancel="eepState.show = false">
        <a-form :label-col="{span:4}" :wrapper-col="{span:16}" v-if="eepState.form">
            <a-form-item label="仓库名称" required>
                <a-input v-model:value="eepState.form.name" />
            </a-form-item>
            <a-form-item label="仓库地址" required>
                <a-input v-model:value="eepState.form.address" />
            </a-form-item>
        </a-form>
    </a-modal>

</div>
</template>

<script >
import { useEnterPot,useEditEnterPot } from '@/models/addons/storage'
import kdPager from '@/components/public/kd-pager'
export default {
    components:{
        kdPager
    },
    setup(){
        let { epState,getEnterPot,deleteEnterPot,changeEnterPot } = useEnterPot()
        getEnterPot(1,epState.limit)
        let { eepState,saveEnterPot ,showEditEnterPot } = useEditEnterPot(epState,getEnterPot )
        return {
            epState,getEnterPot,deleteEnterPot,changeEnterPot,
            eepState,saveEnterPot ,showEditEnterPot
        }
    }
}
</script>

<style scoped>

</style>
